<!-- 首页右边框-主内容 -->
<template>
	<div>
		<el-row :gutter="20">
			<el-col :span="12">
				<el-card class="box-card" style="height: 400px;" shadow="never">
					<div slot="header" class="clearfix"><span>访问量</span></div>
					<!-- 统计图容器 -->
					<div ref="myCharta" style="width: 100%; height: 330px;margin-top: -40px;"></div>
				</el-card>
			</el-col>
			<el-col :span="12">
				<el-card class="box-card" style="height: 400px;" shadow="never">
					<div slot="header" class="clearfix"><span>销售量</span></div>
					<!-- 统计图容器 -->
					<div ref="myChartb" style="width: 100%; height: 393px;margin-top: -40px;"></div>
				</el-card>
			</el-col>
		</el-row>
		<!-- 数据统计 -->
		<el-row :gutter="20" class="my-3">
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="godo('/wenzhang/list/create')">
						<i class="el-icon-user-solid bg-primary h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">发布新闻</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="godo('/shop/goods/create')">
						<i class="el-icon-s-finance bg-success h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">发布产品</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="godo('/web')">
						<i
							class="el-icon-s-order h4 mb-0 text-white text-center mr-3"
							style="width: 40px; height: 40px; text-align: center;line-height: 40px;background-color: #ff54ad;"
						></i>
						<div><small class="text-muted">顾客咨询</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="gobaidu">
						<i class="el-icon-s-data bg-warning h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">热词查询</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="daima">
						<i
							class="el-icon-s-order h4 mb-0 text-white text-center mr-3"
							style="width: 40px; height: 40px; text-align: center;line-height: 40px;background-color: #5591ff;"
						></i>
						<div><small class="text-muted">站点源码</small></div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="4">
				<el-card class="box-card" shadow="hover">
					<div class="d-flex align-items-center" @click="daimas">
						<i class="el-icon-s-finance bg-danger h4 mb-0 text-white text-center mr-3" style="width: 40px; height: 40px; text-align: center;line-height: 40px;"></i>
						<div><small class="text-muted">站点维护</small></div>
					</div>
				</el-card>
			</el-col>
		</el-row>

		<!-- 店铺、订单提示 | 统计图 -->
		<el-row :gutter="20" class="my-3">
			<!-- 店铺、订单提示 -->
			<el-col :span="12" class="d-flex flex-column" style="height: 600px; justify-content: space-between;">
				<el-card class="box-card" shadow="never">
					<div slot="header" class="clearfix">
						<span>咨询地域分布</span>
						<!-- <el-button style="float: right; padding: 3px 0" type="text" @click="godo('/web')">查看咨询表单</el-button> -->
					</div>
					<!-- 统计图容器 -->
					<!-- <ditu></ditu> -->
					<div ref="myChart" style="width: 100%; height: 550px;"></div>
				</el-card>
			</el-col>

			<!-- 统计图 -->
			<el-col :span="12">
				<el-card class="box-card" style="height: 600px;" shadow="never">
					<div slot="header" class="clearfix"><span>官网动态</span><span style="float: right;">{{day}}</span></div>
					<el-row :gutter="20">
						<el-col :span="8">
							<div @click="godo('/wenzhang/list/list')">
							<el-card class="box-card" shadow="hover" style="background-color: #456dff;">
								<div class="text-center align-items-center">
									<div><small style="color: white;">查看文章</small></div>
								</div>
							</el-card>
							</div>
						</el-col>
						<el-col :span="8">
							<div @click="godo('/shop/goods/list')">
							<el-card class="box-card" shadow="hover" style="background-color: #ff3985;">
								<div class="text-center align-items-center">
									<div><small style="color: white;">查看产品</small></div>
								</div>
							</el-card>
							</div>
						</el-col>
						<el-col :span="8">
							<div @click="godo('/web')">
							<el-card class="box-card" shadow="hover" style="background-color: #ffbd14;">
								<div class="text-center align-items-center">
									<div><small style="color: white;">咨询表单</small></div>
								</div>
							</el-card>
							</div>
						</el-col>
					</el-row>
					<el-table :data="tableData" style="width: 100%;margin-top: 20px;" height="420px" border>
						<el-table-column type="index" label="#" width="50" align="center">
						</el-table-column>
						<el-table-column prop="title" label="文章信息">
						</el-table-column>
						<el-table-column prop="yuedu" label="阅读" width="80" align="center">
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>

		
	</div>
</template>

<script>
import { mapState } from 'vuex'; // 引入 全局 公共数据
import 'echarts/map/js/china.js';
import * as vueEcharts from "echarts";
// require('echarts/map/js/china')
let echarts = require('echarts');
export default {
	data() {
		return {
			fanwen:[],//访问数据
			fday:[],//时间
			fwl:[],//访问量
			zixun:[],//咨询量
			day:'',
			tableData: [],
			myChart:null


		};
	},
	// 动态计算过滤器，计算col-3百分比分配页面
	filters: {
		getCol(total) {
			return `col-${12 / total}`;
		}
	},
	computed: {
		// 引入store-modules的user模块里面的user(存储用户相关的数据)
		...mapState({
			user: state => state.user.user,
			navBar: state => state.menu.navBar
		})
	},
	// 生命周期
	created() {
		var now = new Date();
		var year = now.getFullYear(); // 年
		var month = now.getMonth() + 1; // 月
		var date = now.getDate(); // 日
		if (month < 10) month = '0' + month;
		if (date < 10) date = '0' + date;
		this.day = year + '-' + month + '-' + date;
		
		this.axios
			.get('/admin/wangzhe', {
				token: true
			})
			.then(res => {
				let data = res.data.data
				data = data.slice(0,8)
				this.fday = data.map(e=>{
					return e.time
				})
				this.fwl = data.map(e=>{
					return e.fanwen
				})
				this.zixun = data.map(e=>{
					return e.zixun
				})
			});
		this.axios
			.get('/admin/wenzhangs', {
				//商品列表
				token: true
			})
			.then(res => {
				let data = res.data.data;
				this.tableData = data;
			});
	},
	// dom渲染完毕 mounted生命周期
	mounted() {
		// 画统计图
		this.drawLine();
	},
	// 画统计图方法
	methods: {
		godo(e){
			this.$router.push({ path:e })
		},
		gobaidu(){
			window.open('https://index.baidu.com/v2/index.html#/','_blank')
		},
		// 删除单个规格卡片
		daima() {
			this.$confirm('如需获得站点源码，请联系：17328292827', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
		},
		daimas() {
			this.$confirm('24小时站点维护，请联系：17328292827', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
		},
		drawLine() {
			const guangxiJson = require("echarts/map/js/china.js");
			// 初始化echarts实例
			let myChart = echarts.init(this.$refs.myChart);
			let myCharta = echarts.init(this.$refs.myCharta);
			let myChartb = echarts.init(this.$refs.myChartb);
			var that = this;
			// 数据过滤
			// 访问量
			
			
			var that = this
			setTimeout(function() {
				// 配置参数
				myChart.setOption({
					visualMap: {
						//地图图例
						show: true,
						left: 26,
						bottom: 100,
						showLabel: true,
						pieces: [
							//根据数据大小，各省显示不同颜色
							{
								gte: 100,
								label: '>= 1000',
								color: '#1f307b'
							},
							{
								gte: 500,
								lt: 999,
								label: '500 - 999',
								color: '#3c57ce'
							},
							{
								gte: 100,
								lt: 499,
								label: '100 - 499',
								color: '#6f83db'
							},
							{
								gte: 10,
								lt: 99,
								label: '10 - 99',
								color: '#9face7'
							},
							{
								lt: 10,
								label: '<10',
								color: '#bcc5ee'
							}
						]
					},
					geo: {
						//中国地图设置
						map: 'china',
						scaleLimit: {
							min: 1,
							max: 2
						},
						zoom: 1,
						top: 20,
						label: {
							normal: {
								show: true,
								fontSize: '12',
								color: 'rgba(74, 74, 74, 0.7)'
							}
						},
						itemStyle: {
							normal: {
								borderColor: 'rgba(0, 0, 0, 0.2)'
							},
							emphasis: {
								areaColor: '#f2d5ad',
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								borderWidth: 0,
							}
						}
					},
					series: [
						{
							name: '突发事件',
							type: 'map',
							geoIndex: 0,
							data: []
						}
					]
				}),
					myCharta.setOption({
						grid: {
							left: '3%',
							right: '5%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: [
							{
								type: 'category',
								boundaryGap: false,
								data: that.fday
							}
						],
						yAxis: [
							{
								type: 'value'
							}
						],
						series: [
							{
								type: 'line',
								stack: 'Total',
								areaStyle: {},
								emphasis: {
									focus: 'series'
								},
								data: that.fwl
							}
						]
					}),
					myChartb.setOption({
						grid: {
							left: '0%',
							right: '0%',
							bottom: '18%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: that.fday
						},
						yAxis: {
							type: 'value'
						},
						series: [
							{
								data: that.zixun,
								type: 'bar'
							}
						]
					})
			}, 1000);
		},
	},
	beforeUnmount() {
		console.log('beforeUnmount')
		if (!myChart) {
			return
		}
		// window.removeEventListener('resize', this.__resizeHandler)
		myChart.dispose()
		let myChart = null
	},
};
</script>

<style></style>
